var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 18 } }),
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlershowadd },
                },
                [_vm._v(" 添加限定条件 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-table",
            { attrs: { stripe: "", data: _vm.tabledata } },
            [
              _c("el-table-column", {
                attrs: { type: "index", align: "center", label: "序号" },
              }),
              _vm._l(_vm.clmoun, function (list, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: list.prop,
                    label: list.label,
                    align: list.align,
                  },
                  scopedSlots: _vm._u(
                    [
                      list.label == "屏蔽的商品"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("span", [_vm._v(_vm._s(row.goods_count))]),
                              ]
                            },
                          }
                        : list.label == "开关"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-switch", {
                                  attrs: {
                                    "inactive-value": 0,
                                    "active-value": 1,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handlerswitch(row)
                                    },
                                  },
                                  model: {
                                    value: row.door,
                                    callback: function ($$v) {
                                      _vm.$set(row, "door", $$v)
                                    },
                                    expression: "row.door",
                                  },
                                }),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerinfo(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: { click: _vm.handlerremove },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c("el-switch", {
            attrs: {
              "active-text":
                "禁用小单位下单：默认为关  开启后有多种包装单位的商品，小单位不展示且不可下单",
              "inactive-value": "0",
              "active-value": "1",
            },
            on: { change: _vm.handlerzongswit },
            model: {
              value: _vm.door,
              callback: function ($$v) {
                _vm.door = $$v
              },
              expression: "door",
            },
          }),
        ],
        1
      ),
      _c("addproduct", { ref: "add", on: { getlist: _vm.getlist } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }