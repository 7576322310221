<template>
  <div style="padding: 20px">
    <el-row>
      <el-col :span="18"></el-col>
      <el-col :span="4">
        <el-button type="primary" @click="handlershowadd">
          添加限定条件
        </el-button>
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-table stripe :data="tabledata">
        <el-table-column
          type="index"
          align="center"
          label="序号"
        ></el-table-column>
        <el-table-column
          v-for="(list, index) in clmoun"
          :key="index"
          :prop="list.prop"
          :label="list.label"
          :align="list.align"
        >
          <template v-if="list.label == '屏蔽的商品'" #default="{ row }">
            <span>{{ row.goods_count }}</span>
          </template>
          <template v-else-if="list.label == '开关'" #default="{ row }">
            <el-switch
              v-model="row.door"
              :inactive-value="0"
              :active-value="1"
              @change="handlerswitch(row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="{ row }">
            <el-button type="text" @click="handlerinfo(row)">编辑</el-button>
            <el-button type="text" @click="handlerremove">删除</el-button>

          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-switch
        v-model="door"
        active-text="禁用小单位下单：默认为关  开启后有多种包装单位的商品，小单位不展示且不可下单"
        :inactive-value="'0'"
        :active-value="'1'"
        @change="handlerzongswit"
      ></el-switch>
    </el-row>
    <addproduct ref="add" @getlist="getlist"></addproduct>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    name: 'ProductLimited',
    components: {
      addproduct: () => import('./components/addproduct'),
    },
    data() {
      return {
        url: {
          list: '/shopAdmin/goods-limit/index',
          switch: '/shopAdmin/goods-limit/unit-door',
          goodsdoor: '/shopAdmin/goods-limit/limit-door',
        },
        tabledata: [],
        clmoun: [
          {
            prop: 'area_name',
            label: '限定区域',
            align: 'center',
          },
          {
            prop: 'channel_name',
            label: '限定渠道',
            align: 'center',
          },
          {
            prop: 'class_name',
            label: '限定等级',
            align: 'center',
          },
          {
            prop: 'goods_count',
            label: '屏蔽的商品',
            align: 'center',
          },
          {
            prop: '',
            label: '开关',
            align: 'center',
          },
        ],
        door: '',
      }
    },
    mounted() {
      this.handlerlist()
    },
    methods: {
      getlist() {
        this.handlerlist()
      },
      handlerremove() {
        this.$message({
          type: 'warning',
          message: '还没有对应的删除功能及接口',
        })
      },
      handlershowadd() {
        this.$refs.add.showproduct = true
      },
      handlerinfo(row) {
        this.$refs.add.handlerinfodata(row)
      },
      handlerswitch(row) {
        postAction(this.url.goodsdoor, { id: row.id, door: row.door })
          .then((res) => {
            console.log(res)
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerlist()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerzongswit() {
        postAction(this.url.switch)
          .then((res) => {
            console.log(res)
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerlist()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerlist() {
        postAction(this.url.list, {})
          .then((res) => {
            console.log(res)
            this.tabledata = res.data.list
            this.door = res.data.door
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>

<style scoped></style>
